<template>
    <b-container>
        <b-card>
            <b-row>
                <b-col>
                    <b-input-group prepend="Journal Date" class="mt-3">
                        <b-select v-model="date" :options="dates" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-input-group prepend="Image Classifier" class="mt-3">
                        <b-form-input
                            type="text"
                            class="search-box"
                            placeholder="Search.."
                            v-model="query"
                        />
                        <b-input-group>
                            <b-button v-for="(item, index) in path_computed" :key="index" size="sm" variant="success" class="m-1" @click="setPath(item.path)">
                                {{ item.name }} <span v-if="item.count">({{ item.count }})</span>
                            </b-button>
                        </b-input-group>
                        <vienna-tree :items="vienna9_computed" :query="query" :prefix="path_prefix" :counts="vienna_codes" @setId="setId" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-center m-3">
                    <b-button @click="search(1)" variant="success">Search</b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-container>
            <b-row>
                <b-col>
                    <loader v-if="isBusy" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
                    <b-pagination
                        v-model="page_num"
                        :total-rows="total_count"
                        :per-page="per_page"
                        align="center"
                        class="mt-3"
                    ></b-pagination>
                    <b-table :items="results" :busy.sync="isBusy" show-empty show-busy  :fields="fields">
                        <template #cell(show_details)="row">
                            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                                {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                            </b-button>
                        </template>

                        <template #row-details="row">
                            <b-card>
                                <b-row v-if="row.item.imgurl" class="mb-2">
                                    <b-col class="text-center"><img style="max-height: 500px; max-width: 800px" :src="row.item.imgurl" :key="row.item.imgurl" /></b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Status:</b></b-col>
                                    <b-col>{{ row.item.status }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>State:</b></b-col>
                                    <b-col>{{ row.item.state }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Date of App:</b></b-col>
                                    <b-col>{{ row.item.dateofapp }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Proprietor Name:</b></b-col>
                                    <b-col>{{ row.item.propname }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Proprietor Adr:</b></b-col>
                                    <b-col>{{ row.item.proprietoradr }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Business Type:</b></b-col>
                                    <b-col>{{ row.item.buisnesstype }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Buisness Name:</b></b-col>
                                    <b-col>{{ row.item.buisnessname }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>TM Category:</b></b-col>
                                    <b-col>{{ row.item.tmcategory }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>TM Type:</b></b-col>
                                    <b-col>{{ row.item.tmtype }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Goods And Service:</b></b-col>
                                    <b-col>{{ row.item.goodsandserice }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>User Detail:</b></b-col>
                                    <b-col>{{ row.item.userdetail }}</b-col>
                                </b-row>

                                <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                            </b-card>
                        </template>
                        <template #cell(tmappliedfor)="row">
                            <span>{{ row.item.tmappliedfor }}</span>
                            <br />
                            <img v-if="row.item.imgurl" :src="row.item.imgurl" style="max-height: 100px; max-width: 100px" :key="row.item.imgurl" />
                        </template>
                    </b-table>
                    <b-pagination
                        v-model="page_num"
                        :total-rows="total_count"
                        :per-page="per_page"
                        align="center"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import vienna9 from './vienna9.json';
import ViennaTree from './ViennaTree.vue';

export default {
  name: 'journal-search',
  props: {
    items: Array
  },
  components: {
    ViennaTree
  },
  data() {
    return {
        query: "",
        date: null,
        limit: 100,
        dates: [],
        path: [],
        results: [],
        isBusy: false,
        isJournal: false,
        tmname: '',
        page_num: 1,
        per_page: 200,
        total_count: 1,
        vienna_codes: {},
        fields: [
            {
                key: 'appno',
                label: 'Application No'
            },
            {
                key: 'tmappliedfor',
                label: 'Trademark'
            }, 
            {
                key: 'appclass',
                label: 'Class'
            }, 
            {
                key: 'country',
                label: 'Country'
            },
            {
                key: 'show_details',
                label: 'Show Details'
            }
        ],
    }
  },
  created(){
      this.loadJournalDates()
  },
  computed: {
      vienna9_wrapped(){
          return {name: '>', childs: vienna9}
      },
      vienna9_computed(){
          var res = this.vienna9_wrapped
          for(var i = 0; i < this.path.length; i++){
              res = this.findById(res.childs, this.path[i])
          }
          return res.childs
      },
      path_prefix(){
          return this.path.map(function(id){ return id.length == 1 ? '0' + id : id}).join("").replace(">")
      },
      path_computed(){
          var path = Array.from(this.path)
          var breadcrumbs = []
          var item = null
          
          for(var i = 0; i < this.path.length; i++){
              item = this.findByPath(this.vienna9_wrapped, path)
              breadcrumbs.unshift({'name': item.name, 'path': Array.from(path), 'count': this.vienna_codes[Array.from(path).map(function(id){ return id.length == 1 ? '0' + id : id}).join("")]})
              path.pop()
          }
          breadcrumbs.unshift({'name': '>', 'path': []})

          return breadcrumbs
      }
  },
  watch: {
      isJournal(){
          this.$set(this, 'results', []);
      },
      page_num(before, after) {
          if(before != after){
              this.search()
          }
      },
      date(){
          this.country = 'All'
          this.loadViennaCodesForJournal()
      },
      country(){
          this.state = 'All'
          this.loadStatesForCountry()
      },
      search_type(){
          this.code = '';
          this.path = [];
          this.tmname = '';
      }
  },
  methods: {
      loadJournalDates() {
        return this.$api.get('journal_dates').then(r => {
            var dates = [];
            for(var index in r.data) {
                var date = r.data[index];
                if(date == null || date == "")
                    continue
                dates.push({
                    value: date,
                    text: date == null ? 'All' : date.split("-").reverse().join("/")
                })
            }
            this.$set(this, 'dates', dates);
            if(dates.length > 0){
                this.date = dates[dates.length-1].value
            }
        });
      },
      loadCountries() {
        this.country = 'All'
        return this.$api.get('countries').then(r => {
            var countries = [];
            countries.push({
                value: 'All',
                text: 'All'
            })
            for(var index in r.data) {
                var country = r.data[index];
                if(country == null)
                    continue
                countries.push({
                    value: country,
                    text: country
                })
            }
            this.loadStatesForCountry()
            this.$set(this, 'countries', countries);
        });
      },
      loadViennaCodesForJournal() {
        return this.$api.post('viennas_for_journal', {"journal_date": this.date}).then(r => {
            this.$set(this, 'vienna_codes', r.data);
        });
      },
      loadStatesForCountry() {
        return this.$api.post('state_for_country', {"journal_date": this.date, "country": this.country}).then(r => {
            var states = [];
            states.push({
                value: 'All',
                text: 'All'
            })
            for(var index in r.data) {
                var state = r.data[index];
                if(state == null)
                    continue
                states.push({
                    value: state,
                    text: state
                })
            }
            this.$set(this, 'states', states);
        });
      },
      search(page) {
        if(page != undefined){
            this.page_num = page;
        }
        this.isBusy = true;
        this.$api.post('search_journals', {
            "code": this.code,
            "journal_date": this.date,
            "page_num": this.page_num
        }).then(r => {
            this.$set(this, 'results', r.data.results);
            this.$set(this, 'page_num', r.data.page_num);
            this.$set(this, 'total_count', r.data.count);
            this.$set(this, 'per_page', r.data.per_page);
            
            this.isBusy = false;
        }).catch(error => {
          console.log(error)
          this.isBusy = false;
          // Here we could override the busy state, setting isBusy to false
          // this.isBusy = false
          // Returning an empty array, allows table to correctly handle
          // internal busy state in case of error
          return []
        });
      },
      findByPath(items, path){
          var res = this.vienna9_wrapped
          for(var i = 0; i < path.length; i++){
              res = this.findById(res.childs, path[i])
          }
          return res
      },
      findById(items, id){
          for(var i = 0; i < items.length; i++){
              if(items[i].id == id){
                  return items[i]
              }
          }
          return items
      },
      setId(id){
          if(this.path.length > 0){
            this.code = [...this.path, id].map(function(id){ return id.length == 1 ? '0' + id : id}).join("")
          } else {
            this.code = id.length == 1 ? '0' + id : id
          }
          this.search(1)
          if(this.path.length < 2) {
            this.path.push(id);
          }
      },
      setPath(path){
          this.path = path
          this.code = this.path.map(function(id){ return id.length == 1 ? '0' + id : id}).join("")
          this.search(1)
      }
  }
}
</script>