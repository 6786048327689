import Vue from 'vue'
import VueRouter from 'vue-router';
import Router from './router/index.js'
import App from './App.vue'
import axios from 'axios'
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true
import {
  ButtonPlugin, NavbarPlugin, PaginationPlugin, LayoutPlugin, FormInputPlugin, FormGroupPlugin, InputGroupPlugin, CardPlugin, TablePlugin, CollapsePlugin, FormTagsPlugin, FormSelectPlugin
 } from 'bootstrap-vue';

import loader from "vue-ui-preloader"

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.prototype.$api = axios.create({
  baseURL: '/api/'
});

[ButtonPlugin, NavbarPlugin, PaginationPlugin, LayoutPlugin, FormInputPlugin, FormGroupPlugin, InputGroupPlugin, CardPlugin, TablePlugin, CollapsePlugin, FormTagsPlugin, FormSelectPlugin].forEach(comp => {
  Vue.use(comp);
});
Vue.use(loader)
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  router: Router,
  render: h => h(App),
}).$mount('#app')
