<template>
    <b-container>
        <b-row>
            <b-col>
                <template v-for="(item, index) in filtered_items">
                    <b-button size="sm" :key="index" variant="primary" class="m-1" @click="setId(item.id)" v-if="counts[prefix + (item.id < 10 ? '0' + item.id : item.id)]">
                        {{ item.name }} ({{ getCount(item) }})
                    </b-button>
                </template>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'vieanna-tree',
  props: {
    query: String,
    items: Array,
    prefix: String,
    counts: Object,
  },
  data() {
    return {
    }
  },
  created(){

  },
  computed: {
      filtered_items(){
          return this.items.filter(function(item){ return this.query == "" || item.name.toLowerCase().includes(this.query.toLowerCase()) }.bind(this))
      }
  },
  methods: {
      setId(id){
          this.$emit('setId', id)
      },
      getCount(item){
          return this.counts[this.prefix + (item.id < 10 ? '0' + item.id : item.id)]
      }
  }
}
</script>