<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="success">
      <b-navbar-brand href="#">Journal Search</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="https://search-logo.tmpilot.com/">Search Logo By Upload</b-nav-item>
          <b-nav-item :to="{ name: 'ViennaClassification' }" :active="$route.name==='ViennaClassification'">Search Logo by Classifier</b-nav-item>
          <b-nav-item :to="{ name: 'JournalSearch' }" :active="$route.name==='JournalSearch'">Phonetic / WordMark Search</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Phonetic / WordMark Search From TMPilot'
    }
  },
}
</script>

<style>
</style>
