<template>
  <b-form-group class="m-0 p-0">
    <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
    <b-form-tags
      id="tags-component-select"
      v-model="value"
      size="lg"
      add-on-change
      no-outer-focus
    >
      <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
          <li v-for="tag in tags" :key="tag" class="list-inline-item">
            <b-form-tag
              @remove="removeTag(tag)"
              :title="tag"
              :disabled="disabled"
              variant="info"
            >{{ tag }}</b-form-tag>
          </li>
        </ul>
        <b-form-select
          v-bind="inputAttrs"
          v-on="inputHandlers"
          :disabled="disabled || availableOptions.length === 0"
          :options="availableOptions"
          size="sm"
        >
          <template #first>
            <!-- This is required to prevent bugs with Safari -->
            <option disabled value="">Choose a class...</option>
          </template>
        </b-form-select>
      </template>
    </b-form-tags>
  </b-form-group>
</template>

<script>
  export default {
    data() {
      return {
        options: this.generate_classes(),
        value: []
      }
    },
    computed: {
      availableOptions() {
        return this.options.filter(opt => this.value.indexOf(opt) === -1)
      }
    },
    watch: {
        value(v){
            this.$emit('input', v)
        }
    },
    methods: {
        generate_classes() {
            var classes = [];
            for(var i = 1; i <= 45; i++){
                classes.push('' + i)
            }
            return classes
        }
    }
  }
</script>

