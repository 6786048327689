import VueRouter from 'vue-router'
import JournalSearch from '../components/JournalSearch.vue'
import ViennaClassification from '../components/ViennaClassification.vue'
const routes = [
  {
    path: '/',
    name: 'JournalSearch',
    component: JournalSearch,
    meta: {
      title: 'Phonetic / WordMark Search From TMPilot'
    }
  },
  {
    path: '/classifier',
    name: 'ViennaClassification',
    component: ViennaClassification,
    meta: {
      title: 'Image Classifier from TMPilot'
    }
  }
]
const router = new VueRouter({
  routes
})
export default router