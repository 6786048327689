<template>
    <b-container>
        <b-card>
            <b-row>
                <b-col>
                    <b-input-group prepend="Search Type" class="mt-3">
                        <b-select v-model="search_type" :options="search_types"></b-select>
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group prepend="Journal Date" class="mt-3">
                        <b-select v-model="date" :options="dates" />
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group prepend="Classes" class="mt-3">
                        <classes-selector v-model="classes"></classes-selector>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-input-group prepend="Country" class="mt-3">
                        <b-select v-model="country" :options="countries" />
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group prepend="City" class="mt-3">
                        <b-input v-model="city" />
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group prepend="State" class="mt-3">
                        <b-select v-model="state" :options="states" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                <b-input-group prepend="Business Name" class="mt-3">
                        <b-input v-model="buisnessname" />
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group prepend="Business Type" class="mt-3">
                        <b-select v-model="business_type" :options="business_types" />
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group prepend="User Detail" class="mt-3">
                        <b-select v-model="user_detail" :options="user_details" />
                    </b-input-group>
                </b-col>
                <b-col>
                    <b-input-group prepend="Goods And Service" class="mt-3">
                        <b-input v-model="goodsandservice" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-input-group prepend="Wordmark" class="mt-3">
                        <b-select 
                            v-model="tmname_match"
                            :options="tmname_matches"
                        ></b-select>
                        <b-input v-model="tmname" />
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-center m-3">
                    <b-button @click="search(1)" variant="success">Search</b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-container>
            <b-row>
                <b-col>
                    <loader v-if="isBusy" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
                    <b-pagination
                        v-model="page_num"
                        :total-rows="total_count"
                        :per-page="per_page"
                        align="center"
                        class="mt-3"
                    ></b-pagination>
                    <b-table :items="results" :busy.sync="isBusy" show-empty show-busy  :fields="fields">
                        <template #cell(show_details)="row">
                            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                                {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                            </b-button>
                        </template>

                        <template #row-details="row">
                            <b-card>
                                <b-row v-if="row.item.imgurl" class="mb-2">
                                    <b-col class="text-center"><img style="max-height: 500px; max-width: 800px" :src="row.item.imgurl" :key="row.item.imgurl" /></b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Status:</b></b-col>
                                    <b-col>{{ row.item.status }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>State:</b></b-col>
                                    <b-col>{{ row.item.state }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Date of App:</b></b-col>
                                    <b-col>{{ row.item.dateofapp }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Proprietor Name:</b></b-col>
                                    <b-col>{{ row.item.propname }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Proprietor Adr:</b></b-col>
                                    <b-col>{{ row.item.proprietoradr }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Business Type:</b></b-col>
                                    <b-col>{{ row.item.buisnesstype }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Buisness Name:</b></b-col>
                                    <b-col>{{ row.item.buisnessname }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>TM Category:</b></b-col>
                                    <b-col>{{ row.item.tmcategory }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>TM Type:</b></b-col>
                                    <b-col>{{ row.item.tmtype }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Goods And Service:</b></b-col>
                                    <b-col>{{ row.item.goodsandserice }}</b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>User Detail:</b></b-col>
                                    <b-col>{{ row.item.userdetail }}</b-col>
                                </b-row>

                                <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                            </b-card>
                        </template>
                        <template #cell(tmappliedfor)="row">
                            <span>{{ row.item.tmappliedfor }}</span>
                            <br />
                            <img v-if="row.item.imgurl" :src="row.item.imgurl" style="max-height: 100px; max-width: 100px" :key="row.item.imgurl" />
                        </template>
                    </b-table>
                    <b-pagination
                        v-model="page_num"
                        :total-rows="total_count"
                        :per-page="per_page"
                        align="center"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import ClassesSelector from './ClassesSelector.vue';

export default {
  name: 'journal-search',
  props: {
    items: Array
  },
  components: {
    ClassesSelector
  },
  data() {
    return {
        query: "",
        date: null,
        limit: 100,
        classes: [],
        dates: [],
        path: [],
        results: [],
        isBusy: false,
        isJournal: false,
        country: 'All',
        countries: [],
        state: 'All',
        states: [],
        tmname: '',
        page_num: 1,
        per_page: 200,
        total_count: 1,
        tmname_match: 'starts',
        tmname_matches: [
          { text: 'Start With', value: 'starts' },
          { text: 'Contains', value: 'contains' },
          { text: 'Match With', value: 'match' }
        ],
        search_type: 'all',
        search_types: [
          { text: 'All', value: 'all' },
          { text: 'Wordmark', value: 'wordmark' },
          { text: 'Image', value: 'image' },
        ],
        goodsandservice: '',
        buisnessname: '',
        city: '',
        business_type: 'All',
        business_types: [],
        user_detail: 'all',
        user_details: [
          { text: 'All', value: 'all' },
          { text: 'Proposed to be used', value: 'proposed_to_be_used' },
          { text: 'Others', value: 'others' }
        ],
        fields: [
            {
                key: 'appno',
                label: 'Application No'
            },
            {
                key: 'tmappliedfor',
                label: 'Trademark'
            }, 
            {
                key: 'appclass',
                label: 'Class'
            }, 
            {
                key: 'country',
                label: 'Country'
            },
            {
                key: 'show_details',
                label: 'Show Details'
            }
        ],
    }
  },
  created(){
      this.loadJournalDates().then(function(){
          return this.loadCountries()
      }.bind(this)).then(function(){
          return this.loadStatesForCountry()
      }.bind(this)).then(function(){
          return this.loadBusinesTypes()
      }.bind(this))
  },
  computed: {
  },
  watch: {
      isJournal(){
          this.$set(this, 'results', []);
      },
      page_num(before, after) {
          if(before != after){
              this.search()
          }
      },
      date(){
          this.country = 'All'
          this.loadBusinesTypes()
      },
      country(){
          this.loadStatesForCountry()
      },
      search_type(){
          this.tmname = '';
      }
  },
  methods: {
      loadJournalDates() {
        return this.$api.get('journal_dates').then(r => {
            var dates = [];
            for(var index in r.data) {
                var date = r.data[index];
                if(date == null || date == "")
                    continue
                dates.push({
                    value: date,
                    text: date == null ? 'All' : date.split("-").reverse().join("/")
                })
            }
            this.$set(this, 'dates', dates);
            if(dates.length > 0){
                this.date = dates[dates.length-1].value
            }
        });
      },
      loadCountries() {
        this.country = 'All'
        return this.$api.get('countries').then(r => {
            var countries = [];
            countries.push({
                value: 'All',
                text: 'All'
            })
            for(var index in r.data) {
                var country = r.data[index];
                if(country == null)
                    continue
                countries.push({
                    value: country,
                    text: country
                })
            }
            this.loadStatesForCountry()
            this.$set(this, 'countries', countries);
        });
      },
      loadStatesForCountry() {
        this.state = 'All'
        return this.$api.post('state_for_country', {"journal_date": this.date, "country": this.country}).then(r => {
            var states = [];
            states.push({
                value: 'All',
                text: 'All'
            })
            for(var index in r.data) {
                var state = r.data[index];
                if(state == null)
                    continue
                states.push({
                    value: state,
                    text: state
                })
            }
            this.$set(this, 'states', states);
        });
      },
      loadBusinesTypes() {
        this.business_type = 'All'
        return this.$api.post('business_types', {"journal_date": this.date}).then(r => {
            var business_types = [];
            business_types.push({
                value: 'All',
                text: 'All'
            })
            for(var index in r.data) {
                var business_type = r.data[index];
                if(business_type == null)
                    continue
                business_types.push({
                    value: business_type,
                    text: business_type
                })
            }
            this.$set(this, 'business_types', business_types);
        });
      },
      search(page) {
        if(page != undefined){
            this.page_num = page;
        }
        this.isBusy = true;
        this.$api.post('search_journals', {
            "code": this.code,
            "journal_date": this.date, 
            "classes": this.classes, 
            "country": this.country, 
            "city": this.city, 
            "state": this.state, 
            "search_type": this.search_type, 
            "tmname_match": this.tmname_match, 
            "tmname": this.tmname, 
            "goodsandservice": this.goodsandservice, 
            "buisnessname": this.buisnessname,
            "business_type": this.business_type,
            "user_detail": this.user_detail,
            "page_num": this.page_num
        }).then(r => {
            this.$set(this, 'results', r.data.results);
            this.$set(this, 'page_num', r.data.page_num);
            this.$set(this, 'total_count', r.data.count);
            this.$set(this, 'per_page', r.data.per_page);
            
            this.isBusy = false;
        }).catch(error => {
          console.log(error)
          this.isBusy = false;
          // Here we could override the busy state, setting isBusy to false
          // this.isBusy = false
          // Returning an empty array, allows table to correctly handle
          // internal busy state in case of error
          return []
        });
      }
  }
}
</script>